<template>
  <div id="MyCollection">
    <div class="posFix" ref="posFix">
      <!-- <div class="topSearch">
        <van-search
          v-model="search"
          shape="round"
          background="#23252E"
          placeholder="请输入搜索关键词"
          @input = focusChange
          style="flex:1"
        />
      </div> -->
  
      <div class="hotMain" >
        <div class="forth">
          <div>我的收藏</div>
          <div>
            <span @click="show = !show;letters = false" :class="{'white':show}">
              <van-icon v-if="show" name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(90deg);" />
              <van-icon v-else name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(-90deg);" />
              综合排序
            </span>
            <span @click="letters = !letters;show=false" :class="{'white':letters}"><img src="../../assets/image/findOut/flitGray.png" />筛选</span>
          </div>
        </div>
      </div>
    </div>
    <div class="blackFix" ref="blackFix"></div>

    <div>
      <van-popup v-model="letters" position="top" style="width:100%">
        <div class="topPop">
          <div class="titleTab">
            <div :class="{'active':cateId == ''}" @click="cateId = ''">全部</div>
            <div :class="{'active':cateId == i.id}" v-for="(i,item) in getDocCate" :key="item" @click="cateId = i.id">{{i.name}}</div>
          </div>
          <div class="anscontain"><span @click="cateId = ''">重置</span><span @click="checkCateId">完成</span></div>
        </div>
      </van-popup>
      <van-popup v-model="show" position="top" style="width:100%">
        <div class="sort">
          <p :class="{'active':sort == 0}" @click="checkSort(0)">智能排序</p>
          <p :class="{'active':sort == 1}" @click="checkSort(1)">按时间升序</p>
          <p :class="{'active':sort == 2}" @click="checkSort(2)">按时间降序</p>
          <p :class="{'active':sort == 3}" @click="checkSort(3)">按阅读量升序</p>
          <p :class="{'active':sort == 4}" @click="checkSort(4)">按阅读量降序</p>
        </div>
      </van-popup>
    </div>
      
    <div ref="topMost"></div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="InfoMain"
    >
      <div v-for="(i,item) in userCollectList" :key="item">
        <div class="first">
          <div><span>{{i.author}}</span><span>{{Global.clearWhite($moment(new Date(Date.parse((i.publish_at).replace(/-/g, '/')))).fromNow())}}</span></div>
          <span>
            <van-icon @click="noStar(i.id,item)" v-if="getCollectIds.indexOf(i.id) != '-1'" name="star" style="font-size:0.15rem"  color="#E59813" />
          </span>
        </div>
        <div class="fifth" @click="toLink(i.doc_file_info.path,i.doc_file_info.id)">
          <img v-if="i.doc_file_info.type == 1" src="../../assets/image/home/doc_type_1.png" />
          <img v-else-if="i.doc_file_info.type == 2" src="../../assets/image/home/doc_type_2.png" />
          <img v-else-if="i.doc_file_info.type == 3" src="../../assets/image/home/doc_type_3.png" />
          <img v-else-if="i.doc_file_info.type == 4" src="../../assets/image/home/doc_type_4.png" />
          <img v-else-if="i.doc_file_info.type == 5" src="../../assets/image/home/doc_type_5.png" />
          <div>
            <p>{{i.doc_file_info.name}}</p>
            <p>{{i.doc_file_info.ext}} {{i.doc_file_info.size}}</p>
          </div>
        </div>
        <div class="forth">
          <div><span>{{i.category.name}}</span></div>
          <div>
            <span>{{i.view_count}}浏览</span>
            <!-- <span>5432下载</span> -->
          </div>
        </div>
      </div>
    </van-list>
      
  </div>
</template>
<script>
import startn from '../../assets/image/findOut/startn.png'
export default {
  name: "MyCollection",
  data(){
    return{
      show:false,
      letters:false,
      getDocCate:[],//文件分类
      userCollectList:[],//文件列表
      finished:false,
      loading:true,
      next_page_url:'',
      sort:0,
      cateId:'',
      search:'',
      getCollectIds:[]
    }
  },
  mounted() {
    this.$refs.blackFix.style.top = this.$refs.posFix.offsetHeight + 'px'
    this.$refs.topMost.style.paddingTop = this.$refs.posFix.offsetHeight + this.$refs.blackFix.offsetHeight+'px'

    // 文件类型
    this.axios({
      method: 'GET',
      url: '/doc/getDocCate',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.getDocCate = res.data.data;
        console.log(this.getDocCate)
      }
    })
     // 用户收藏文件id
    this.axios({
      method: 'GET',
      url: 'doc/getCollectIds',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.getCollectIds = res.data.data
      }
    })
    // 文件列表
    this.axios({
      method: 'GET',
      url: '/doc/userCollectList?sort=0',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.loading = false
        this.userCollectList = res.data.data.list.data;
        this.next_page_url = res.data.data.list.next_page_url
      }
    })
  },
  methods:{
    toLink(link,id){
      this.axios({
        method: 'PATCH',
        url: '/doc/addDocViewCount/'+id,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          window.location.href = link
        }
      })      
    },
    checkSort(a){
      this.show = false
      this.userCollectList = []
      this.sort = a
      this.firstLoad()
    },
    checkCateId(){
      this.letters = false
      this.userCollectList = []
      this.sort = 0
      this.next_page_url = ''
      this.firstLoad()
    },
    // 搜索文件 搜藏没有搜索
    focusChange(){
      console.log('???')
      window.scrollTo(0,0)
      this.axios({
        method: 'GET',
        url: '/doc/searchDoc?sort='+this.sort+'&cateId='+this.cateId,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.userCollectList = []
          if(res.data.data){
            console.log(res.data.data.list)
            this.userCollectList = res.data.data.list.data;
            this.next_page_url = res.data.data.list.next_page_url
            console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    firstLoad(){
      this.next_page_url = ''
      window.scrollTo(0,0)
      this.axios({
        method: 'GET',
        url: '/doc/userCollectList?sort='+this.sort+'&cateId='+this.cateId+'&search='+this.search,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.userCollectList = []
          if(res.data.data){
            console.log(res.data.data.list)
            this.userCollectList = res.data.data.list.data;
            this.next_page_url = res.data.data.list.next_page_url
            console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    onLoad(){
      if(this.next_page_url){
        var url;
        if(this.search){
          url = this.next_page_url+'&sort='+this.sort+'&cateId='+this.cateId+'&search='+this.search
        }else{
          url = this.next_page_url+'&sort='+this.sort+'&cateId='+this.cateId
        }
        this.axios({
          method: 'GET',
          url: url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.userCollectList.push(...res.data.data.list.data)
              this.next_page_url = res.data.data.list.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.list.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
     noStar(docId,item){
      this.axios({
          method: 'PATCH',
          url: '/doc/collectFile?sort='+this.sort+'&docId='+docId,
          data:{
            status:0,
            docId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            var index = this.getCollectIds.indexOf(docId);
            this.getCollectIds.splice(index, 1);
            this.userCollectList.splice(item, 1);
            this.$toast({
              message: '取消收藏',
              icon: startn,
            })
          }
        })
    },
   
    
  }
};
</script>
<style lang="less" scoped>
#MyCollection {
  .posFix{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: #16171D;
    >div{
      background: #23252E;
    }
  }
  .blackFix{
    z-index: 3;
    background: #16171D;
    height: 0.11rem;
    position: fixed;
    width: 100%;
  }
  .InfoMain{
    padding: 0 0.15rem;
    >div:last-child{
      border-bottom: none;
    }
    >div{
      padding: 0.18rem 0;
      border-bottom: .1px solid #5C5D6E61;
      .fifth{
        background: #3D404F;
        border-radius: 0.02rem;
        padding: 0.11rem 0.15rem;
        display: flex;
        justify-content: flex-start;
        margin: 0.15rem 0 0.12rem;
        >div{
          >p:last-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
        >img{
          width: 0.3rem;
          margin-right: 0.16rem;
        }
      }
      .forth{
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
          }
        }
        >div:first-child{
          display: flex;
          >span{
            color: #9091A3;
            padding: 0 0.05rem;
            background: #3D404F;
            border-radius: 0.02rem;
            margin-right: 0.04rem;
            font-size: 0.12rem;
          }
        }
      }
      
      .first{
        >div{
          color: #989AB1;
          font-size: 0.14rem;
          display: flex;
          align-items: center;
          >span{
            margin: 0 0.04rem;
          }
        }
        >span{
          color: #727387;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .topSearch{
    display: flex;
    padding: 0.15rem;
    .right{
      display: flex;
      align-items: center;
      color: #9091A3;
      font-size: 0.12rem;
    }
  }
  .sort{
    padding: 0.15rem 0.3rem 0.05rem;
    >p{
      color: #989AB1;
      font-size: 0.12rem;
      margin-bottom: 0.12rem;
    }
    .active{
      color: #3890FF;
    }
  }
  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTab{
    display: flex;
    flex-wrap: wrap;
    padding: 0.15rem 0.15rem 0.1rem 0.15rem;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  } 
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  
  .personalContent{
    margin-top: 0.1rem;
  }
  .hotMain{
    padding-top: 0.15rem;
    .forth{
      display: flex;
      justify-content: space-between;
      padding:0 0.15rem 0.15rem;
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            >img{
              width: 0.12rem;
              margin:0.02rem 0.05rem 0 0.13rem;
            }
          }
          .white{
            color: #F6F7FD;
          }
        }
        >div:first-child{
          color: #F6F7FD;
          font-size: 0.16rem;
        }
      }
  }
  >div{
    background: #23252E;
  }
}
</style>